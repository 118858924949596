import PropTypes from 'prop-types';

import { DayjsShape } from '../../shapes/dayjs.shape';

export const StaffStatusEvolutionShape = PropTypes.shape({
  status: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  quantityPeriod1: PropTypes.number.isRequired,
  quantityPeriod2: PropTypes.number.isRequired,
  evolution: PropTypes.number.isRequired,
});

export const StaffStatusEvolutionFiltersShape = PropTypes.shape({
  school: PropTypes.string.isRequired,
  period1SchoolYear: PropTypes.string.isRequired,
  period2SchoolYear: PropTypes.string.isRequired,
  period1DateFrom: DayjsShape.isRequired,
  period1DateTo: DayjsShape.isRequired,
  period2DateFrom: DayjsShape.isRequired,
  period2DateTo: DayjsShape.isRequired,
  productType: PropTypes.string.isRequired,
});
