import React from 'react';

import { TableCell } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import TableTotalFooter from '../../../../components/TableTotalFooter';
import { IRegistrationType } from '../../../../types/registration-type';
import { IStaffStatus } from '../../../../types/staff-status';
import { ArrayUtils } from '../../../../utils/array.utils';
import { StaffStatusShape } from '../../shapes';

interface IProps {
  statistics: IStaffStatus[];
  statuses: string[];
}

const StaffStatusListFooter: React.FC<IProps> = React.memo(({ statistics, statuses }) => {
  const { t } = useTranslation();

  const renderTotalRow = React.useMemo(
    () =>
      statuses
        .map((status) => {
          const filtered = statistics.filter((item) => item.code === status);

          return [IRegistrationType.new, IRegistrationType.old, 'total'].map((key) => (
            <TableCell key={`${status}_${key}`}>
              {ArrayUtils.calculateTotal(filtered, key as keyof IStaffStatus)}
            </TableCell>
          ));
        })
        .flat(),
    [statistics, statuses],
  );

  return (
    <TableTotalFooter>
      <TableCell sx={{ fontWeight: 'bold' }}>{t(`general.total`)}</TableCell>
      {renderTotalRow}
      <TableCell>{ArrayUtils.calculateTotal(statistics, 'total')}</TableCell>
    </TableTotalFooter>
  );
});

StaffStatusListFooter.propTypes = {
  statistics: PropTypes.arrayOf(StaffStatusShape.isRequired).isRequired,
  statuses: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
};

export default StaffStatusListFooter;
