import React from 'react';

import PropTypes from 'prop-types';

import {
  IPreInscriptionAcademy,
  IPreInscriptionSituationItemFields,
} from '../../../../types/pre-inscription-academy.type';
import { PreInscriptionAcademyFieldsType, PreInscriptionAcademyShape } from '../../shapes';
import PreInscriptionAcademyListItem from '../PreInscriptionAcademyListItem';

interface IProps {
  group: IPreInscriptionSituationItemFields;
  academies: IPreInscriptionAcademy[];
}

const PreInscriptionAcademyListGroup: React.FC<IProps> = React.memo(({ group, academies }) =>
  academies.map((academy, idx) => (
    <PreInscriptionAcademyListItem
      group={group}
      academy={academy}
      isFirst={idx === 0}
      totalAcademies={academies.length}
      key={`section_group_list_${academy.code}_${group}`}
    />
  )),
);

PreInscriptionAcademyListGroup.propTypes = {
  group: PreInscriptionAcademyFieldsType.isRequired,
  academies: PropTypes.arrayOf(PreInscriptionAcademyShape.isRequired).isRequired,
};

export default PreInscriptionAcademyListGroup;
