import React from 'react';

import { TableBody } from '@mui/material';
import PropTypes from 'prop-types';

import {
  IPreInscriptionAcademy,
  IPreInscriptionSituationItemFields,
} from '../../../../types/pre-inscription-academy.type';
import { PreInscriptionAcademyShape } from '../../shapes';
import PreInscriptionAcademyListGroup from '../PreInscriptionAcademyListGroup';

interface IProps {
  academies: IPreInscriptionAcademy[];
}

const groups: IPreInscriptionSituationItemFields[] = ['responseDa', 'afterRapo', 'responseRapo'];

const PreInscriptionAcademyListBody: React.FC<IProps> = React.memo(({ academies }) => (
  <TableBody>
    {groups.map((group) => (
      <PreInscriptionAcademyListGroup key={group} group={group} academies={academies} />
    ))}
  </TableBody>
));

PreInscriptionAcademyListBody.propTypes = {
  academies: PropTypes.arrayOf(PreInscriptionAcademyShape.isRequired).isRequired,
};

export default PreInscriptionAcademyListBody;
