import React from 'react';

import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AssessmentIcon from '@mui/icons-material/Assessment';
import DatasetIcon from '@mui/icons-material/Dataset';
import DonutLargeIcon from '@mui/icons-material/DonutLarge';
import HomeIcon from '@mui/icons-material/Home';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import PieChartIcon from '@mui/icons-material/PieChart';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import SchoolIcon from '@mui/icons-material/School';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import List from '@mui/material/List';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import './style.scss';

const menuItems = [
  { route: '/', icon: <HomeIcon />, label: 'dashboard' },
  { route: '/staff', icon: <RecentActorsIcon />, label: 'staff' },
  { route: '/staff/students', icon: <DatasetIcon />, label: 'staff_students' },
  { route: '/staff/evolution', icon: <AssessmentIcon />, label: 'staff_evolution' },
  { route: '/staff/status', icon: <PieChartIcon />, label: 'status' },
  { route: '/staff/status/evolution', icon: <TrendingUpIcon />, label: 'status_evolution' },
  { route: '/staff/statistics', icon: <DonutLargeIcon />, label: 'staff_statistics' },
  { route: '/inscriptions', icon: <HowToRegIcon />, label: 'inscriptions' },
  { route: '/inscriptions/details', icon: <DatasetIcon />, label: 'inscriptions_details' },
  { route: '/pre-inscriptions', icon: <HowToRegIcon />, label: 'pre_inscriptions' },
  {
    route: '/pre-inscriptions/summary',
    icon: <QueryStatsIcon />,
    label: 'pre_inscriptions_summary',
  },
  {
    route: '/pre-inscriptions/academies',
    icon: <AccountBalanceIcon />,
    label: 'pre_inscriptions_academies',
  },
  {
    route: '/pre-inscriptions/school',
    icon: <SchoolIcon />,
    label: 'pre_inscriptions_school',
  },
  {
    route: '/pre-inscriptions/reasons',
    icon: <LeaderboardIcon />,
    label: 'pre_inscriptions_reasons',
  },
];

const Menu: React.FC = React.memo(() => {
  const { t } = useTranslation();

  return (
    <List component="nav">
      {menuItems.map((item) => (
        <ListItemButton key={item.label} component={NavLink} to={item.route} end>
          <ListItemIcon>{item.icon}</ListItemIcon>
          <ListItemText primary={t(`menu.${item.label}`)} sx={{ color: '#636363' }} />
        </ListItemButton>
      ))}
    </List>
  );
});

export default Menu;
