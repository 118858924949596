import { ApiHelper } from './api.helper';
import { IStatus } from '../types/status.type';

export const fetchStatuses = async (): Promise<IStatus[]> => {
  const response = await fetch(ApiHelper.makeUrl('status'), ApiHelper.makeOptions());
  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message);
  }

  return data;
};
