import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import PreInscriptionSchoolFilters from './components/PreInscriptionSchoolFilters';
import PreInscriptionSchoolList from './components/PreInscriptionSchoolList';
import { IFilters } from './types';
import {
  downloadPreInscriptionsSchool,
  fetchPreInscriptionsSchool,
} from '../../api/pre-inscription-school';
import ContentWrapper from '../../components/ContentWrapper';
import ProgressSection from '../../components/ProgressSection';
import { IPreInscriptionSchool } from '../../types/pre-inscription-school.type';
import { HttpUtils } from '../../utils/http.utils';

const PreInscriptionsSchool = React.memo(() => {
  const [statistics, setStatistics] = useState<IPreInscriptionSchool | null>(null);
  const [isSubmitProcess, setSubmitProcess] = useState<boolean>(false);
  const [isExportProcess, setExportProcess] = useState<boolean>(false);
  const [isReady, setReady] = useState<boolean>(false);

  const { t } = useTranslation();

  const handleSubmit = React.useCallback(
    (filters: IFilters) => {
      setSubmitProcess(true);

      if (!isReady) {
        setReady(true);
      }

      fetchPreInscriptionsSchool(filters)
        .then((response) => {
          setStatistics(response);
        })
        .catch(() => setStatistics(null))
        .finally(() => setSubmitProcess(false));
    },
    [isReady],
  );

  const handleExport = React.useCallback(
    (filters: IFilters) => {
      setExportProcess(true);

      if (!isReady) {
        setReady(true);
      }

      downloadPreInscriptionsSchool(filters)
        .then((response) => HttpUtils.handleBlob(response, 'pre_inscriptions_school.xlsx'))
        .finally(() => setExportProcess(false));
    },
    [isReady],
  );

  const renderList = React.useMemo(() => {
    if (!isReady) {
      return null;
    }

    if (isSubmitProcess || !statistics) {
      return <ProgressSection mt={3} />;
    }

    return <PreInscriptionSchoolList statistics={statistics} />;
  }, [statistics, isReady, isSubmitProcess]);

  return (
    <ContentWrapper title={t('pre_inscriptions_school.title')}>
      <PreInscriptionSchoolFilters
        onSubmit={handleSubmit}
        onExport={handleExport}
        isSubmitProcess={isSubmitProcess}
        isExportProcess={isExportProcess}
      />

      {renderList}
    </ContentWrapper>
  );
});

export default PreInscriptionsSchool;
