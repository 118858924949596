import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import PreInscriptionsReasonFilters from './components/PreInscriptionsReasonFilters';
import PreInscriptionsReasonList from './components/PreInscriptionsReasonList';
import { IFilters } from './types';
import {
  downloadPreInscriptionsReasons,
  fetchPreInscriptionsReasons,
} from '../../api/pre-inscription-reason';
import ContentWrapper from '../../components/ContentWrapper';
import ProgressSection from '../../components/ProgressSection';
import { IPreInscriptionReason } from '../../types/pre-inscription-reason.type';
import { HttpUtils } from '../../utils/http.utils';

const PreInscriptionsReasons = React.memo(() => {
  const [reasons, setReasons] = useState<IPreInscriptionReason[]>([]);
  const [isSubmitProcess, setSubmitProcess] = useState<boolean>(false);
  const [isExportProcess, setExportProcess] = useState<boolean>(false);
  const [isReady, setReady] = useState<boolean>(false);

  const { t } = useTranslation();

  const handleSubmit = React.useCallback(
    (filters: IFilters) => {
      setSubmitProcess(true);

      if (!isReady) {
        setReady(true);
      }

      fetchPreInscriptionsReasons(filters)
        .then((response) => {
          setReasons(response);
        })
        .catch(() => setReasons([]))
        .finally(() => setSubmitProcess(false));
    },
    [isReady],
  );

  const handleExport = React.useCallback(
    (filters: IFilters) => {
      setExportProcess(true);

      if (!isReady) {
        setReady(true);
      }

      downloadPreInscriptionsReasons(filters)
        .then((response) => HttpUtils.handleBlob(response, 'pre_inscriptions_reasons.xlsx'))
        .finally(() => setExportProcess(false));
    },
    [isReady],
  );

  const renderList = React.useMemo(() => {
    if (!isReady) {
      return null;
    }

    if (isSubmitProcess) {
      return <ProgressSection mt={3} />;
    }

    return <PreInscriptionsReasonList reasons={reasons} />;
  }, [reasons, isReady, isSubmitProcess]);

  return (
    <ContentWrapper title={t('pre_inscriptions_reasons.title')}>
      <PreInscriptionsReasonFilters
        onSubmit={handleSubmit}
        onExport={handleExport}
        isSubmitProcess={isSubmitProcess}
        isExportProcess={isExportProcess}
      />

      {renderList}
    </ContentWrapper>
  );
});

export default PreInscriptionsReasons;
