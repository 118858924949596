import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import PreInscriptionAcademyFilters from './components/PreInscriptionAcademyFilters';
import PreInscriptionAcademyList from './components/PreInscriptionAcademyList';
import { IFilters } from './types';
import {
  downloadPreInscriptionsAcademies,
  fetchPreInscriptionsAcademies,
} from '../../api/pre-inscription-academy';
import ContentWrapper from '../../components/ContentWrapper';
import ProgressSection from '../../components/ProgressSection';
import { IPreInscriptionAcademy } from '../../types/pre-inscription-academy.type';
import { HttpUtils } from '../../utils/http.utils';

const PreInscriptionsAcademies = React.memo(() => {
  const [academies, setAcademies] = useState<IPreInscriptionAcademy[]>([]);
  const [isSubmitProcess, setSubmitProcess] = useState<boolean>(false);
  const [isExportProcess, setExportProcess] = useState<boolean>(false);
  const [isReady, setReady] = useState<boolean>(false);

  const { t } = useTranslation();

  const handleSubmit = React.useCallback(
    (filters: IFilters) => {
      setSubmitProcess(true);

      if (!isReady) {
        setReady(true);
      }

      fetchPreInscriptionsAcademies(filters)
        .then((response) => {
          setAcademies(response);
        })
        .catch(() => setAcademies([]))
        .finally(() => setSubmitProcess(false));
    },
    [isReady],
  );

  const handleExport = React.useCallback(
    (filters: IFilters) => {
      setExportProcess(true);

      if (!isReady) {
        setReady(true);
      }

      downloadPreInscriptionsAcademies(filters)
        .then((response) => HttpUtils.handleBlob(response, 'pre_inscriptions_academies.xlsx'))
        .finally(() => setExportProcess(false));
    },
    [isReady],
  );

  const renderList = React.useMemo(() => {
    if (!isReady) {
      return null;
    }

    if (isSubmitProcess) {
      return <ProgressSection mt={3} />;
    }

    return <PreInscriptionAcademyList academies={academies} />;
  }, [academies, isReady, isSubmitProcess]);

  return (
    <ContentWrapper title={t('pre_inscriptions_academies.title')}>
      <PreInscriptionAcademyFilters
        onSubmit={handleSubmit}
        onExport={handleExport}
        isSubmitProcess={isSubmitProcess}
        isExportProcess={isExportProcess}
      />

      {renderList}
    </ContentWrapper>
  );
});

export default PreInscriptionsAcademies;
