import React from 'react';

import PropTypes from 'prop-types';

import { SummaryStatisticsShape } from '../../../../shapes/summary-statistics.shape';
import { IRegistrationType } from '../../../../types/registration-type';
import { ISummaryStatistics } from '../../../../types/summary.type';
import PreInscriptionSummaryListFraction from '../PreInscriptionSummaryListFraction';
import PreInscriptionSummaryListItem from '../PreInscriptionSummaryListItem';

interface IProps {
  statistics: ISummaryStatistics;
  type: string;
  groupCode: string;
}

const PreInscriptionSummaryListSection: React.FC<IProps> = React.memo(
  ({ groupCode, type, statistics }) => (
    <>
      <PreInscriptionSummaryListItem statistics={statistics} groupCode={groupCode} type={type} />
      <PreInscriptionSummaryListFraction statistics={statistics} />
    </>
  ),
);

PreInscriptionSummaryListSection.propTypes = {
  statistics: SummaryStatisticsShape.isRequired,
  type: PropTypes.oneOf([...Object.keys(IRegistrationType), 'total']).isRequired,
  groupCode: PropTypes.string.isRequired,
};

export default PreInscriptionSummaryListSection;
