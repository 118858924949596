import React from 'react';

import { Table, TableBody, TableCell, TableHead, TableRow, Theme } from '@mui/material';
import Grid from '@mui/material/Grid';
import { SxProps } from '@mui/system';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { SummaryShape } from '../../shapes/summary-statistics.shape';
import { IRegistrationType } from '../../types/registration-type';
import { ISummary } from '../../types/summary.type';
import SummaryStatistics from '../SummaryStatistics';

interface IProps {
  title: string;
  statistics: ISummary;
}

const sxTableHead: SxProps<Theme> = { '& th': { fontWeight: 'bold' } };

const sections: (keyof ISummary)[] = [IRegistrationType.old, IRegistrationType.new, 'total'];

const SummaryItem: React.FC<IProps> = React.memo(({ title, statistics }) => {
  const { t } = useTranslation();

  return (
    <Grid container justifyContent="space-between" p={2}>
      <Grid item xs={5}>
        <Table>
          <TableHead sx={sxTableHead}>
            <TableRow>
              <TableCell>{title}</TableCell>
              <TableCell align="center">{t('summary.agreement')}</TableCell>
              <TableCell align="center">{t('summary.refusal')}</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>

          <TableBody>
            {sections.map((section) => (
              <SummaryStatistics
                key={`summary_da_${section}`}
                title={t(`summary.${section}`)}
                statistics={statistics[section].responseDa}
              />
            ))}
          </TableBody>
        </Table>
      </Grid>

      <Grid item xs={5}>
        <Table>
          <TableHead sx={sxTableHead}>
            <TableRow>
              <TableCell>{t('summary.after_rapo')}</TableCell>
              <TableCell align="center">{t('summary.agreement')}</TableCell>
              <TableCell align="center">{t('summary.refusal')}</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>

          <TableBody>
            {sections.map((section) => (
              <SummaryStatistics
                key={`summary_after_rapo_${section}`}
                title={t(`summary.${section}`)}
                statistics={statistics[section].afterRapo}
              />
            ))}
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  );
});

SummaryItem.propTypes = {
  title: PropTypes.string.isRequired,
  statistics: SummaryShape.isRequired,
};

export default SummaryItem;
