import PropTypes from 'prop-types';

import { SummaryStatisticsShape } from '../../shapes/summary-statistics.shape';

export const PreInscriptionSummaryShape = PropTypes.shape({
  groupCode: PropTypes.string.isRequired,
  new: SummaryStatisticsShape.isRequired,
  old: SummaryStatisticsShape.isRequired,
  total: SummaryStatisticsShape.isRequired,
});
