import React, { useState } from 'react';

import { IFilters } from '../types/evolution-filters.type';

const validation = (filters: IFilters) => {
  if (!filters.period1SchoolYear.length || !filters.period2SchoolYear.length) {
    return false;
  }

  if (!filters.period1DateFrom?.isValid() || !filters.period2DateFrom?.isValid()) {
    return false;
  }

  return filters.period1DateTo?.isValid() && filters.period2DateTo?.isValid();
};

export const useIsValidEvolutionFilters = (filters: IFilters) => {
  const [isValid, setIsValid] = useState<boolean>(false);

  React.useEffect(() => {
    setIsValid(validation(filters));
  }, [filters]);

  return isValid;
};
