import PropTypes from 'prop-types';

import { IPreInscriptionSituationItemFields } from '../../types/pre-inscription-academy.type';

export const PreInscriptionAcademySituationItemShape = PropTypes.shape({
  accepted: PropTypes.number.isRequired,
  acceptedFraction: PropTypes.number.isRequired,
  refused: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
});

export const PreInscriptionAcademySituationShape = PropTypes.shape({
  name: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  responseDa: PreInscriptionAcademySituationItemShape.isRequired,
  afterRapo: PreInscriptionAcademySituationItemShape.isRequired,
  responseRapo: PreInscriptionAcademySituationItemShape.isRequired,
  total: PropTypes.number.isRequired,
});

export const PreInscriptionAcademyTotalShape = PropTypes.shape({
  responseDa: PreInscriptionAcademySituationItemShape.isRequired,
  afterRapo: PreInscriptionAcademySituationItemShape.isRequired,
  responseRapo: PreInscriptionAcademySituationItemShape.isRequired,
});

export const PreInscriptionAcademyShape = PropTypes.shape({
  name: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  situations: PropTypes.arrayOf(PreInscriptionAcademySituationShape.isRequired).isRequired,
  total: PreInscriptionAcademyTotalShape.isRequired,
});

export const PreInscriptionAcademyFieldsType = PropTypes.oneOf([
  'responseDa',
  'afterRapo',
  'responseRapo',
] as IPreInscriptionSituationItemFields[]);
