import { ApiHelper } from './api.helper';
import { IStaffStatusEvolution } from '../types/staff-status-evolution.type';
import { HttpUtils } from '../utils/http.utils';
import { IFilters, IRequestParams } from '../views/StaffStatusEvolution/types';

export const fetchStatusEvolution = async (filters: IFilters): Promise<IStaffStatusEvolution[]> => {
  const queryParams = HttpUtils.toQueryParams<IFilters, IRequestParams>(filters);
  const url = `${ApiHelper.makeUrl('staff/status/evolution')}?${queryParams}`;
  const response = await fetch(url, ApiHelper.makeOptions());
  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message);
  }

  return data;
};

export const downloadStatusEvolution = async (filters: IFilters): Promise<Blob> => {
  const queryParams = HttpUtils.toQueryParams<IFilters, IRequestParams>(filters);
  const url = `${ApiHelper.makeUrl('staff/status/evolution/export')}?${queryParams}`;

  const response = await fetch(url, ApiHelper.makeOptions());
  const data = response.blob();

  if (!response.ok) {
    const responseData = await response.json();

    throw new Error(await responseData.message);
  }

  return data;
};
