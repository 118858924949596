import React from 'react';

import { Table, TableContainer } from '@mui/material';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';

import { IPreInscriptionAcademy } from '../../../../types/pre-inscription-academy.type';
import { PreInscriptionAcademyShape } from '../../shapes';
import PreInscriptionAcademyListBody from '../PreInscriptionAcademyListBody';
import PreInscriptionAcademyListHead from '../PreInscriptionAcademyListHead';

interface IProps {
  academies: IPreInscriptionAcademy[];
}

const PreInscriptionAcademyList: React.FC<IProps> = React.memo(({ academies }) => (
  <Grid container mt={3}>
    <Grid item xs={12}>
      <TableContainer component={Paper}>
        <Table>
          <PreInscriptionAcademyListHead academies={academies} />
          <PreInscriptionAcademyListBody academies={academies} />
        </Table>
      </TableContainer>
    </Grid>
  </Grid>
));

PreInscriptionAcademyList.propTypes = {
  academies: PropTypes.arrayOf(PreInscriptionAcademyShape.isRequired).isRequired,
};

export default PreInscriptionAcademyList;
