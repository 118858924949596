import React from 'react';

import { TableBody } from '@mui/material';
import PropTypes from 'prop-types';

import { IStaffStatusEvolution } from '../../../../types/staff-status-evolution.type';
import { StaffStatusEvolutionShape } from '../../shapes';
import StaffStatusEvolutionListItem from '../StaffStatusEvolutionListItem';

interface IProps {
  evolution: IStaffStatusEvolution[];
}

const StaffStatusEvolutionListBody: React.FC<IProps> = React.memo(({ evolution }) => (
  <TableBody>
    {evolution.map((item) => (
      <StaffStatusEvolutionListItem key={item.code} evolution={item} />
    ))}
  </TableBody>
));

StaffStatusEvolutionListBody.propTypes = {
  evolution: PropTypes.arrayOf(StaffStatusEvolutionShape.isRequired).isRequired,
};

export default StaffStatusEvolutionListBody;
