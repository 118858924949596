import React from 'react';

import { TableCell, TableRow } from '@mui/material';
import PropTypes from 'prop-types';

import { SummaryStatisticsItemShape } from '../../shapes/summary-statistics.shape';
import { ISummaryStatisticsItem } from '../../types/summary.type';

interface IProps {
  title: string;
  statistics: ISummaryStatisticsItem;
}

const SummaryStatistics: React.FC<IProps> = React.memo(({ title, statistics }) => (
  <>
    <TableRow>
      <TableCell>{title}</TableCell>
      <TableCell align="center">{statistics.accepted}</TableCell>
      <TableCell align="center">{statistics.refused}</TableCell>
      <TableCell align="center">{statistics.total}</TableCell>
    </TableRow>

    <TableRow>
      <TableCell />
      <TableCell align="center">{statistics.acceptedFraction}%</TableCell>
      <TableCell align="center">{statistics.refusedFraction}%</TableCell>
      <TableCell />
    </TableRow>
  </>
));

SummaryStatistics.propTypes = {
  title: PropTypes.string.isRequired,
  statistics: SummaryStatisticsItemShape.isRequired,
};

export default SummaryStatistics;
