import React from 'react';

import { Table, TableContainer } from '@mui/material';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';

import { IStaffStatusEvolution } from '../../../../types/staff-status-evolution.type';
import { StaffStatusEvolutionFiltersShape, StaffStatusEvolutionShape } from '../../shapes';
import { IFilters } from '../../types';
import StaffStatusEvolutionListBody from '../StaffStatusEvolutionListBody';
import StaffStatusEvolutionListHead from '../StaffStatusEvolutionListHead';

interface IProps {
  filters: IFilters;
  evolution: IStaffStatusEvolution[];
}

const StaffStatusEvolutionList: React.FC<IProps> = React.memo(({ evolution, filters }) => (
  <Grid container mt={3}>
    <Grid item xs={12}>
      <TableContainer component={Paper}>
        <Table>
          <StaffStatusEvolutionListHead filters={filters} />
          <StaffStatusEvolutionListBody evolution={evolution} />
        </Table>
      </TableContainer>
    </Grid>
  </Grid>
));

StaffStatusEvolutionList.propTypes = {
  filters: StaffStatusEvolutionFiltersShape.isRequired,
  evolution: PropTypes.arrayOf(StaffStatusEvolutionShape.isRequired).isRequired,
};

export default StaffStatusEvolutionList;
