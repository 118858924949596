import React from 'react';

import { SelectChangeEvent } from '@mui/material';
import { Dayjs } from 'dayjs';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { EvolutionFiltersShape } from './shapes';
import { SchoolYearShape } from '../../shapes/school-year.shape';
import { IFilters } from '../../types/evolution-filters.type';
import { ISchoolYear } from '../../types/school-year.type';
import FormDatePicker from '../FormDatePicker';
import FormSelect from '../FormSelect';

interface IProps {
  filters: IFilters;
  schoolYears: ISchoolYear[];
  handleSelectChange: <T = string>(event: SelectChangeEvent<T>) => void;
  handleDateChange: (name: string, value: Dayjs | null) => void;
}

const EvolutionDateFilters: React.FC<IProps> = React.memo(
  ({ filters, schoolYears, handleSelectChange, handleDateChange }) => {
    const { t } = useTranslation();

    return (
      <>
        <FormSelect
          label={t('evolution.school_year_period1')}
          name="period1SchoolYear"
          value={filters.period1SchoolYear}
          items={schoolYears}
          onChange={handleSelectChange}
        />

        <FormDatePicker
          label={t('evolution.date_from_period1')}
          value={filters.period1DateFrom}
          onChange={(value) => handleDateChange('period1DateFrom', value)}
        />

        <FormDatePicker
          label={t('evolution.date_to_period1')}
          value={filters.period1DateTo}
          onChange={(value) => handleDateChange('period1DateTo', value)}
        />

        <FormSelect
          label={t('evolution.school_year_period2')}
          name="period2SchoolYear"
          value={filters.period2SchoolYear}
          items={schoolYears}
          onChange={handleSelectChange}
        />

        <FormDatePicker
          label={t('evolution.date_from_period2')}
          value={filters.period2DateFrom}
          onChange={(value) => handleDateChange('period2DateFrom', value)}
        />

        <FormDatePicker
          label={t('evolution.date_to_period2')}
          value={filters.period2DateTo}
          onChange={(value) => handleDateChange('period2DateTo', value)}
        />
      </>
    );
  },
);

EvolutionDateFilters.propTypes = {
  filters: EvolutionFiltersShape.isRequired,
  schoolYears: PropTypes.arrayOf(SchoolYearShape.isRequired).isRequired,
  handleSelectChange: PropTypes.func.isRequired,
  handleDateChange: PropTypes.func.isRequired,
};

export default EvolutionDateFilters;
