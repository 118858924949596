import { useEffect, useState } from 'react';

import { fetchSchools } from '../api/school';
import { fetchSchoolYears } from '../api/school-year';
import { ISchoolYear } from '../types/school-year.type';
import { ISchool } from '../types/school.type';

interface IDataPromise {
  key: 'school' | 'years';
  promise: Promise<ISchool[]> | Promise<ISchoolYear[]>;
}

export const useFetchStaffStatusFiltersData = () => {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [schools, setSchools] = useState<ISchool[]>([]);
  const [schoolYears, setSchoolYears] = useState<ISchoolYear[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      const promises: IDataPromise[] = [
        { key: 'school', promise: fetchSchools() },
        { key: 'years', promise: fetchSchoolYears() },
      ];
      const items = await Promise.allSettled(promises.map(({ promise }) => promise));

      items.forEach((item, idx) => {
        const data = item.status === 'fulfilled' ? item.value : [];
        const { key } = promises[idx];

        switch (key) {
          case 'school':
            setSchools(data as ISchool[]);

            break;

          case 'years':
            setSchoolYears(data as ISchoolYear[]);

            break;

          default:
            break;
        }
      });
    };

    fetchData().finally(() => setLoading(false));
  }, []);

  return { schools, schoolYears, isLoading };
};
