export class StringUtils {
  /**
   * Generate random string
   *
   * @param length
   *
   * @return string
   */
  static random = (length = 24): string => {
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890';

    let text = '';

    for (let i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return text;
  };

  /**
   * Convert string from camel case to snake case
   *
   * @param str
   */
  static camelToSnakeCase = (str: string): string =>
    str.replace(/[A-Z]/g, (letter: string) => `_${letter.toLowerCase()}`);

  /**
   * Convert string to key format
   *
   * @param str
   */
  static toKey = (str: string): string => str.replace(' ', '_').toLowerCase();
}
