import React from 'react';

import { IPreInscriptionSummary } from '../../../../types/pre-inscription-summary.type';
import { IRegistrationType } from '../../../../types/registration-type';
import { ISummaryStatistics } from '../../../../types/summary.type';
import { PreInscriptionSummaryShape } from '../../shapes';
import PreInscriptionSummaryListSection from '../PreInscriptionSummaryListSection';

interface IProps {
  inscription: IPreInscriptionSummary;
}

const types = [IRegistrationType.new, IRegistrationType.old, 'total'] as Array<
  keyof IPreInscriptionSummary
>;

const PreInscriptionSummaryListGroup: React.FC<IProps> = React.memo(({ inscription }) => (
  <>
    {types.map((key) => (
      <PreInscriptionSummaryListSection
        key={`summary_item_${key}`}
        groupCode={inscription.groupCode}
        statistics={inscription[key] as ISummaryStatistics}
        type={key}
      />
    ))}
  </>
));

PreInscriptionSummaryListGroup.propTypes = {
  inscription: PreInscriptionSummaryShape.isRequired,
};

export default PreInscriptionSummaryListGroup;
