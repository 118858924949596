import React from 'react';

import { SelectChangeEvent } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { PRODUCT_TYPE_LIST } from '../../constants/product-type.constant';
import { ControlSx } from '../../types/control.type';
import { IFormSelectItem } from '../../types/form-select-item.type';
import FormSelect from '../FormSelect';

type IProps = ControlSx & {
  value: string;
  name?: string;
  onChange: <T>(event: SelectChangeEvent<T>) => void;
};

const FormProductTypeSelect: React.FC<IProps> = React.memo(({ name = 'productType', ...props }) => {
  const { t } = useTranslation();

  const PRODUCT_TYPES: IFormSelectItem[] = PRODUCT_TYPE_LIST.map((code) => ({
    code,
    name: t(`product_type.${code.toLowerCase()}`),
  }));

  return (
    <FormSelect
      label={t('general.product_type')}
      name={name}
      items={PRODUCT_TYPES}
      useEmptyValue
      {...props}
    />
  );
});

FormProductTypeSelect.defaultProps = {
  controlSx: {},
  name: 'productType',
};

FormProductTypeSelect.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  controlSx: PropTypes.object,
  name: PropTypes.string,
};

export default FormProductTypeSelect;
