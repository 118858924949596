import React from 'react';

import { TableBody } from '@mui/material';
import PropTypes from 'prop-types';

import { StatusHelper } from '../../../../helpers/status.helper';
import { IStaffStatus } from '../../../../types/staff-status';
import { StaffStatusShape } from '../../shapes';
import StaffStatusListItem from '../StaffStatusListItem';

interface IProps {
  statistics: IStaffStatus[];
  statuses: string[];
}

const StaffStatusListBody: React.FC<IProps> = React.memo(
  ({ statistics, statuses: uniqueStatuses }) => {
    const renderList = React.useMemo(() => {
      if (!statistics?.length) {
        return null;
      }

      const grouped = StatusHelper.groupStatisticsBySchool(statistics);

      return Object.entries(grouped)
        .map(([school, items]) => (
          <StaffStatusListItem
            key={school}
            school={school}
            statistics={items}
            statuses={uniqueStatuses}
          />
        ))
        .flat();
    }, [statistics, uniqueStatuses]);

    return <TableBody>{renderList}</TableBody>;
  },
);

StaffStatusListBody.propTypes = {
  statistics: PropTypes.arrayOf(StaffStatusShape.isRequired).isRequired,
  statuses: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
};

export default StaffStatusListBody;
