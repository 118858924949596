import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import StaffStatusFilters from './components/StaffStatusFilters';
import StaffStatusList from './components/StaffStatusList';
import { IFilters } from './types';
import { downloadStaffStatus, fetchStaffStatus } from '../../api/staff-status';
import ContentWrapper from '../../components/ContentWrapper';
import ProgressSection from '../../components/ProgressSection';
import { IStaffStatus } from '../../types/staff-status';
import { HttpUtils } from '../../utils/http.utils';

const StaffStatus = React.memo(() => {
  const [statistics, setStatistics] = useState<IStaffStatus[]>([]);
  const [isSubmitProcess, setSubmitProcess] = useState<boolean>(false);
  const [isExportProcess, setExportProcess] = useState<boolean>(false);
  const [isReady, setReady] = useState<boolean>(false);

  const { t } = useTranslation();

  const handleSubmit = React.useCallback(
    (filters: IFilters) => {
      setSubmitProcess(true);

      if (!isReady) {
        setReady(true);
      }

      fetchStaffStatus(filters)
        .then((response) => {
          setStatistics(response);
        })
        .catch(() => setStatistics([]))
        .finally(() => setSubmitProcess(false));
    },
    [isReady],
  );

  const handleExport = React.useCallback(
    (filters: IFilters) => {
      setExportProcess(true);

      if (!isReady) {
        setReady(true);
      }

      downloadStaffStatus(filters)
        .then((response) => HttpUtils.handleBlob(response, 'status_statistics.xlsx'))
        .finally(() => setExportProcess(false));
    },
    [isReady],
  );

  const renderList = React.useMemo(() => {
    if (!isReady) {
      return null;
    }

    if (isSubmitProcess) {
      return <ProgressSection mt={3} />;
    }

    return <StaffStatusList statistics={statistics} />;
  }, [isReady, isSubmitProcess, statistics]);

  return (
    <ContentWrapper title={t('staff_status.title')}>
      <StaffStatusFilters
        onSubmit={handleSubmit}
        onExport={handleExport}
        isSubmitProcess={isSubmitProcess}
        isExportProcess={isExportProcess}
      />

      {renderList}
    </ContentWrapper>
  );
});

export default StaffStatus;
