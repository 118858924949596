import { useEffect, useState } from 'react';

import { fetchAcademies } from '../api/academy';
import { fetchSchools } from '../api/school';
import { fetchSchoolYears } from '../api/school-year';
import { IAcademy } from '../types/academy.type';
import { ISchoolYear } from '../types/school-year.type';
import { ISchool } from '../types/school.type';

interface IDataPromise {
  key: 'school' | 'years' | 'academies';
  promise: Promise<ISchool[]> | Promise<ISchoolYear[]> | Promise<IAcademy[]>;
}

export const useFetchPreInscriptionAcademyFiltersData = () => {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [schools, setSchools] = useState<ISchool[]>([]);
  const [schoolYears, setSchoolYears] = useState<ISchoolYear[]>([]);
  const [academies, setAcademies] = useState<IAcademy[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      const promises: IDataPromise[] = [
        { key: 'school', promise: fetchSchools() },
        { key: 'years', promise: fetchSchoolYears() },
        { key: 'academies', promise: fetchAcademies() },
      ];

      const items = await Promise.allSettled(promises.map(({ promise }) => promise));

      items.forEach((item, idx) => {
        const data = item.status === 'fulfilled' ? item.value : [];
        const { key } = promises[idx];

        switch (key) {
          case 'school':
            setSchools(data as ISchool[]);

            break;

          case 'years':
            setSchoolYears(data as ISchoolYear[]);

            break;

          case 'academies':
            setAcademies(data as IAcademy[]);

            break;

          default:
            break;
        }
      });
    };

    fetchData().finally(() => setLoading(false));
  }, []);

  return {
    schools,
    schoolYears,
    academies,
    isLoading,
  };
};
