import { IStaffStatus, IStaffStatusGroup } from '../types/staff-status';

export class StatusHelper {
  /**
   * Group statistics by school
   *
   * @param statistics
   */
  static groupStatisticsBySchool = (statistics: IStaffStatus[]): IStaffStatusGroup => {
    const grouped: IStaffStatusGroup = {};

    statistics.forEach((item) => {
      const { school } = item;

      if (!grouped[school]) {
        grouped[school] = [];
      }

      grouped[school].push(item);
    });

    return grouped;
  };
}
