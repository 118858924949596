import React from 'react';

import { TableCell, TableHead, TableRow, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { useTranslation } from 'react-i18next';

import { StaffStatusEvolutionFiltersShape } from '../../shapes';
import { IFilters } from '../../types';

interface IProps {
  filters: IFilters;
}

const dateFormat = 'DD/MM/YYYY';
const sxTableHead: SxProps<Theme> = { '& th': { fontWeight: 'bold', textAlign: 'center' } };

const StaffStatusEvolutionListHead: React.FC<IProps> = React.memo(({ filters }) => {
  const { t } = useTranslation();

  const [period1Label, setPeriod1Label] = React.useState<string>('');
  const [period2Label, setPeriod2Label] = React.useState<string>('');

  React.useEffect(() => {
    const { period1DateFrom, period1DateTo, period2DateFrom, period2DateTo } = filters;

    const period1 = `${period1DateFrom.format(dateFormat)} - ${period1DateTo.format(dateFormat)}`;
    const period2 = `${period2DateFrom.format(dateFormat)} - ${period2DateTo.format(dateFormat)}`;

    setPeriod1Label(period1);
    setPeriod2Label(period2);
  }, [filters]);

  return (
    <TableHead>
      {filters.school && (
        <TableRow sx={sxTableHead}>
          <TableCell colSpan={4}>{t(`school.${filters.school}`)}</TableCell>
        </TableRow>
      )}

      <TableRow sx={sxTableHead}>
        <TableCell>{t(`general.status`)}</TableCell>

        <TableCell>
          <div>{period1Label}</div>
          <div>{filters.period1SchoolYear}</div>
        </TableCell>

        <TableCell>
          <div>{period2Label}</div>
          <div>{filters.period2SchoolYear}</div>
        </TableCell>

        <TableCell>{t(`general.evolution`)}</TableCell>
      </TableRow>
    </TableHead>
  );
});

StaffStatusEvolutionListHead.propTypes = {
  filters: StaffStatusEvolutionFiltersShape.isRequired,
};

export default StaffStatusEvolutionListHead;
