import PropTypes from 'prop-types';

export const SummaryStatisticsItemShape = PropTypes.shape({
  accepted: PropTypes.number.isRequired,
  acceptedFraction: PropTypes.number.isRequired,
  refused: PropTypes.number.isRequired,
  refusedFraction: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
});

export const SummaryStatisticsShape = PropTypes.shape({
  responseDa: SummaryStatisticsItemShape.isRequired,
  responseRapo: SummaryStatisticsItemShape.isRequired,
  afterRapo: SummaryStatisticsItemShape.isRequired,
});

export const SummaryShape = PropTypes.shape({
  new: SummaryStatisticsShape.isRequired,
  old: SummaryStatisticsShape.isRequired,
  total: SummaryStatisticsShape.isRequired,
});
