import { ApiHelper } from './api.helper';
import { IPreInscriptionSchool } from '../types/pre-inscription-school.type';
import { HttpUtils } from '../utils/http.utils';
import { IFilters, IRequestParams } from '../views/PreInscriptionsSchool/types';

export const fetchPreInscriptionsSchool = async (
  filters: IFilters,
): Promise<IPreInscriptionSchool> => {
  const queryParams = HttpUtils.toQueryParams<IFilters, IRequestParams>(filters);
  const url = `${ApiHelper.makeUrl('pre-inscriptions/school')}?${queryParams}`;
  const response = await fetch(url, ApiHelper.makeOptions());
  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.message);
  }

  return data;
};

export const downloadPreInscriptionsSchool = async (filters: IFilters): Promise<Blob> => {
  const queryParams = HttpUtils.toQueryParams<IFilters, IRequestParams>(filters);
  const url = `${ApiHelper.makeUrl('pre-inscriptions/school/export')}?${queryParams}`;

  const response = await fetch(url, ApiHelper.makeOptions());
  const data = response.blob();

  if (!response.ok) {
    const responseData = await response.json();

    throw new Error(await responseData.message);
  }

  return data;
};
